* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif; /* Update to 'Futura' if hosting the font locally */
}

body, html, #root {
  height: 100%;
  width: 100%;
}

.App {
  text-align: center;
  background-color: #d1d1d1; /* Very slight shade of gray */
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Arial', sans-serif;
  overflow-y: auto; /* Allow vertical scrolling */
  height: 100vh; /* Set height to viewport height */
}

.App-logo {
  width: 100%;
  height: auto;
  max-width: 400px;
  max-height: 80vh;
  object-fit: contain;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 20px;
  padding-bottom: 10px;
}
.App-info {
  justify-content: space-around;
}

.App-main {
  padding-top: 40px;
}

h1 {
  font-size: 2.5em;
  margin-top: 20px;
}

p {
  font-size: 1.2em;
  max-width: 600px;
  margin-top: 20px;
}
