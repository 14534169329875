.email-capture-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    background-color: #d1d1d1;
    padding: 10px;
}

.email-capture-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #d1d1d1;
    padding: 10px;
    padding-bottom: 100px;
    border-radius: 5px;
}

.email-capture-label {
    margin-bottom: 10px;
    font-size: 16px;
}

.email-capture-input {
    width: 200px;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.email-capture-button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.email-capture-button:hover {
    background-color: #0056b3;
}

.email-capture-error {
    color: red;
    margin-top: 10px;
}