.App-footer {
    background-color: #d1d1d1; /* Slightly darker shade of gray */
    text-align: center;
    padding: 5px 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
}

.App-footer p {
    margin: 0;
    font-size: 0.5em;
}

.App-footer nav {
    margin-top: 15px;
    /* Future styles for directory links */
}
